:root {
  --main-bg-color: #fed90f;
  --card-even-color: #70d1fe;
  --card-odd-color: #d1b271;
  --white: #fff;
  --liked: #f14e28;
}

body {
  background-color: var(--main-bg-color);
  scroll-behavior: smooth;
}

h1 {
  color: var(--white);
  font-size: 3rem;
  text-align: center;
}

input {
  font-family: inherit;
}

.logo {
  display: block;
  width: 20rem;
  max-width: 100%;
  margin: 0 auto 3rem auto;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1360px;
  margin: 0 auto;
}

.action_holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.search_box {
  width: 80%;
  margin: 0 1rem 0 0;
  padding: 1rem 1rem;
  border: 0;
  border-radius: 0.3rem;
  outline: none;
  font-size: 1.1rem;
}

.search_box::placeholder {
  text-align: center;
}

.like_count {
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 2.4rem;
}

.like_count i {
  margin-right: 0.5rem;
}

.character {
  background-color: var(--card-odd-color);
  margin-bottom: 2rem;
}

.character_grid {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: var(--white);
  padding: 2rem 1.5rem;
}

.character:nth-child(even) {
  background-color: var(--card-even-color);
}

h2 {
  font-size: 2.4rem;
}

p {
  font-size: 1.75rem;
  font-weight: 200;
  text-align: center;
}

i {
  display: block;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.5 ease-in-out;
}

i:hover {
  transform: scale(1.2);
}

.cta_section {
  display: flex;
  width: 100%;
  padding-top: 4rem;
  justify-content: center;
  grid-column: 2;
}

.like_holder {
  margin-right: 2rem;
}

.liked {
  color: var(--liked);
}

.delete:hover > i {
  color: var(--liked);
}

.loading {
  margin: 0 auto;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hourglass {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
}
.hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0.8rem;
  box-sizing: border-box;
  border: 32px solid var(--white);
  border-color: var(--white) transparent var(--white) transparent;
  animation: hourglass 1.2s infinite;
}
@keyframes hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@media screen and (min-width: 992px) {
  .logo {
    width: 30rem;
  }

  .action_holder {
    flex-direction: row;
  }

  .search_box {
    width: 30%;
  }
  .character_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 4rem 0;
  }

  /* .like_holder {
    margin-right: 4rem;
  } */
}
